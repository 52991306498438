import CompRebateLayoutTwo from '@/components/rebate/comp-rebate-layout-two/index.vue';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getRecommendDetail } from '@/service/rebate/novNewRebate';
import Rmb from '@/components/common/rmb/index.vue';

export default {
    name: 'nov-new-rebate-recommend-award',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutTwo,
        RebateTable,
        Rmb,
    },
    data() {
        return {};
    },
    computed: {
        //  直接推荐展示的行名
        directColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '名称',
                    key: 'name',
                },
                {
                    label: '团购金额',
                    isRmb: true,
                    key: 'recommendTotalSale',
                },
                {
                    label: '推荐奖励',
                    isRmb: true,
                    key: 'recommendAward',
                },
            ];
            //不是战略
            if (userInfo.levelId !== '4') {
                columns[0].render = function render(value) {
                    return `${value}团队`;
                };
            }

            return columns;
        },
        //  间接推荐展示的行名
        indirectColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '名称',
                    key: 'name',
                },
                {
                    label: '团购金额',
                    isRmb: true,
                    key: 'indirectTotalSale',
                },
                {
                    label: '推荐奖励',
                    isRmb: true,
                    key: 'indirectAward',
                },
            ];
            //不是战略
            if (userInfo.levelId !== '4') {
                columns[0].render = function render(value) {
                    return `${value}团队`;
                };
            }

            return columns;
        },
        //  直接推荐奖
        directRecomendList() {
            if (this.rebateInfo) {
                return this.rebateInfo.recommendList || [];
            }
            return [];
        },
        //  间接推荐奖
        indirectRecomendList() {
            if (this.rebateInfo) {
                return this.rebateInfo.indirectList || [];
            }
            return [];
        },

        // 合伙人推荐奖励
        bonusSum() {
            if (this.rebateInfo) {
                return Number(this.rebateInfo.totalAward);
            }
            return '--';
        },
        sumTextObj() {
            const { userInfo } = this;
            let amountText = '';
            let bonusText = '';
            switch (userInfo.levelCode) {
                case 'GS':
                case 'DS':
                    amountText = '推荐团购金额';
                    bonusText = '推荐奖励';
                    break;
                case 'LHCSR':
                    amountText = '创始人推荐团购金额';
                    bonusText = '创始人推荐奖励';
                    break;
                case 'ZLHHR':
                    amountText = '合伙人推荐团购金额';
                    bonusText = '合伙人推荐奖励';
                    break;
                default:
                    break;
            }
            return {
                amountText,
                bonusText,
            };
        },
        // 合伙人推荐团购金额
        amountSum() {
            if (this.rebateInfo) {
                return Number(this.rebateInfo.totalSale);
            }
            return '--';
        },

        // 直推团队 小计
        recommendSum() {
            if (this.rebateInfo) {
                const recommendList = this.rebateInfo.recommendList || [];
                return recommendList.reduce((sum, val) => {
                    return Number(sum) + Number(val.recommendAward);
                }, 0);
            }
            return '--';
        },

        // 间推团队 小计
        indirectSum() {
            if (this.rebateInfo) {
                const indirectList = this.rebateInfo.indirectList || [];
                return indirectList.reduce((sum, val) => {
                    return Number(sum) + Number(val.indirectAward);
                }, 0);
            }
            return '--';
        },
    },

    methods: {
        //  初始化页面
        init() {
            const {
                startTime,
                endTime,
                agentId,
                userInfo: { levelId },
                curActivityId,
                curSystemId,
            } = this;
            getRecommendDetail({
                startTime,
                endTime,
                userId: agentId,
                systemId: curSystemId,
                levelId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
